import area from "./address"

//收集省数据
function gatherProvinceList () {
  const allData = area;
  return allData.filter(item => {
    if (item.parent === "0") {
      return item;
    }
  })
}

//根据传入数据获取市/区数据
function gatherCityList (data) {
  const allData = area;
  return allData.filter(item => {
    if (item.parent === data) {
      return item;
    }
  })
}

//获取省数据
export const getProvinceList = () => {
  return gatherProvinceList();
}

//获取市/区数据
export const getCityOrDistrictList = data => {
  return gatherCityList(data);
}

export default {
  getProvinceList,
  getCityOrDistrictList
}


